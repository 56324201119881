export default {
    name: 'base',
    path: '',
    component: () => import('./index'),
    redirect: {name: 'index'},
    children: [
        {
            name: 'course',
            path: '/course',
            component: () => import('./course'),
        }, {
            name: 'practice',
            path: '/practice',
            component: () => import('./practice'),
        }, {
            name: 'sourceStore',
            path: '/sourcestore',
            component: () => import('./sourceStore'),
        }, {
            name: 'exams',
            path: '/exams',
            component: () => import('./exams'),
        }, {
            name: 'cooperate',
            path: '/cooperate',
            component: () => import('./cooperate'),
        }, {
            name: 'case',
            path: '/case',
            component: () => import('./case'),
        }, {
            name: 'project',
            path: '/project',
            component: () => import('./project'),
        }
    ]
}